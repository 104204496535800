/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/foundation-sites@6.5.1/dist/js/foundation.min.js
 * - /npm/foundation-sites@6.5.1/dist/js/plugins/foundation.equalizer.min.js
 * - /npm/foundation-sites@6.5.1/dist/js/plugins/foundation.offcanvas.min.js
 * - /npm/foundation-sites@6.5.1/dist/js/plugins/foundation.toggler.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
